import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CharactersView from '../views/CharactersView.vue'
import BaroviaView from '../views/BaroviaView.vue'
import ItemsView from '../views/ItemsView.vue'
import NPCsView from '../views/NPCsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/characters',
    name: 'characters',
    component: CharactersView,
  },
  {
    path: '/barovia',
    name: 'barovia',
    component: BaroviaView,
  },
  {
    path: '/items',
    name: 'items',
    component: ItemsView,
  },
  {
    path: '/npcs',
    name: 'npcs',
    component: NPCsView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
